<template>
  <div class="min-h-screen bg-gray-100">
    <div class="max-w-6xl mx-auto py-12">
      <h1 class="text-3xl font-semibold text-center mb-8">教师专属的AI心理助手</h1>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <router-link to="/chat1" class="bg-white p-6 rounded-lg shadow-md flex items-start space-x-4 cursor-pointer mx-4">
          <div class="bg-blue-600 text-white p-3 rounded-full w-12 h-12 flex items-center justify-center">
            <i class="fas fa-heart"></i>
          </div>
          <div>
            <h2 class="text-xl font-semibold">识心小念</h2>
            <p class="text-gray-600">陪伴助手，疏导日常情绪困扰</p>
          </div>
        </router-link>
        <router-link to="/chat2" class="bg-white p-6 rounded-lg shadow-md flex items-start space-x-4 cursor-pointer mx-4">
          <div class="bg-green-500 text-white p-3 rounded-full w-12 h-12 flex items-center justify-center">
            <i class="fas fa-clock"></i>
          </div>
          <div>
            <h2 class="text-xl font-semibold">理论助手</h2>
            <p class="text-gray-600">辅助学科老师撰写心理教案</p>
          </div>
        </router-link>
        <router-link to="/chat3" class="bg-white p-6 rounded-lg shadow-md flex items-start space-x-4 cursor-pointer mx-4">
          <div class="bg-yellow-500 text-white p-3 rounded-full w-12 h-12 flex items-center justify-center">
            <i class="fas fa-user-secret"></i>
          </div>
          <div>
            <h2 class="text-xl font-semibold">案例助手</h2>
            <p class="text-gray-600">解答各类心理问题的案例小百科</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');

html, body, #app {
  height: 100%;
  margin: 0;
}
</style>