<template>
  <iframe src="http://39.102.57.51:8006/chatbot/XB2i0Kpqq6dyzHon" allow="microphone"></iframe>
</template>

<script>
export default {
  name: 'ChatThree'
}
</script>
<style>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>