import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '../components/HomePage.vue'
import ChatOne from '../components/ChatOne.vue'
import ChatTwo from '../components/ChatTwo.vue'
import ChatThree from '../components/ChatThree.vue'

Vue.use(Router)
export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/chat1',
      name: 'ChatOne',
      component: ChatOne
    },
    {
      path: '/chat2',
      name: 'ChatTwo',
      component: ChatTwo
    },
    {
      path: '/chat3',
      name: 'ChatThree',
      component: ChatThree
    }
  ]
})